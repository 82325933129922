import Cmp from './modules/cmp';
import Commercial from './modules/commercial';
import Gallery from './modules/gallery';
import SharingTools from './modules/sharingtools';

const modules = {
  Cmp,
  Commercial,
  Gallery,
  SharingTools
};

var elements = document.querySelectorAll('.joomla-script-options');
if (elements.length > 0) {
  for (let i = 0; i < elements.length; i++) {
    var options = JSON.parse(elements[i].text || elements[i].textContent);
    options.modules.forEach((module) => {
      if (modules[module.name]) {
        new modules[module.name](module.options);
      }
    });
  }
}
