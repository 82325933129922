
class SharingTools {
    constructor(options) {
        var defaults = {};
        this.params = Object.assign({}, defaults, options || {});
        this.run();
    }

    run() {
        window.shareToMessenger = this.shareToMessenger;
        window.isMobile = this.isMobile;
        window.copyItemUrl = this.copyItemUrl;
    }

    shareToMessenger(url) {
        if (window.isMobile()) {
            window.location.href = 'fb-messenger://share/?app_id=1725610871097937&link=' + url;
        } else {
            window.open('https://www.facebook.com/dialog/send?app_id=1725610871097937&link=' + url + '&redirect_uri=' + url, '_blank');
        }
    }

    isMobile() {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if (/windows phone/i.test(userAgent)) {
            return true;
        }

        if (/android/i.test(userAgent)) {
            return true;
        }

        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return true;
        }

        return false;
    }

    copyItemUrl(element) {

        if (!navigator.clipboard) {
            return;
        }

        const url = element.dataset.url;

        if (!url) {
            return;
        }

        navigator.clipboard.writeText(url).then(
            () => {
                element.classList.add('copied');
                window.setTimeout(() => {
                    element.classList.remove('copied');
                }, 5000);

            }, () => {
                console.log('Copy URL failed');
            }
        );

    }


}

export {
    SharingTools as
        default
};
