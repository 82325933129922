import {
  debounce
} from 'debounce';

class Commercial {
  constructor(options) {

    var defaults = {
      param: '',
      breakpoints: [
        300,
        768,
        1024
      ],
      devices: [
        'mobile',
        'tablet',
        'desktop'
      ],
      personalized: true,
      prebid: true,
      preroll: true,
      loadScripts: false
    };

    this.params = Object.assign({}, defaults, options || {});

    this.previousViewport = null;
    this.currentViewport = null;
    this.firstLoad = true;

    this.adElements = document.querySelectorAll('.js-ad-slot');

    googletag.cmd.push(() => {
      if (this.params.personalized) {
        googletag.pubads().setTargeting("personalized", "yes");
      } else {
        googletag.pubads().setTargeting("personalized", "no");
        googletag.pubads().setRequestNonPersonalizedAds(1);
      }
    });

    if (this.params.loadScripts && this.params.prebid == true) {
      const ocm = document.createElement('script');
      ocm.src = "https://cdn.orangeclickmedia.com/tech/ratpack.gr/ocm.js";
      ocm.async = true;
      document.head.appendChild(ocm);
    }

    if (this.params.loadScripts) {
      const gpt = document.createElement('script');
      gpt.src = "https://securepubads.g.doubleclick.net/tag/js/gpt.js";
      gpt.async = true;
      document.head.appendChild(gpt);
    }

    if (this.params.preroll) {
      if (typeof window.dfp_targeting.pagetype !== 'undefined') {
        window.adTagUrl = 'https://pubads.g.doubleclick.net/gampad/live/ads?iu=/6873491/Ratpack_Preroll&description_url=https%3A%2F%2Fwww.ratpack.gr%2F&env=vp&impl=s&correlator=&tfcd=0&npa=0&gdfp_req=1&output=vast&sz=640x480&unviewed_position_start=1';

        const preroll = document.createElement('script');
        preroll.src = "https://play.bbend.com/preroll/v30/js/main.min.js";
        preroll.async = true;
        document.head.appendChild(preroll);
      }
    }

    this.setViewport();
    this.showAds();
    this.addEvents();
  }

  addEvents() {
    window.addEventListener('resize', debounce((event) => {

      this.firstLoad = false;

      this.setViewport();

      if (this.adSlots.length && this.previousViewport != this.currentViewport) {
        googletag.destroySlots(this.adSlots);

        this.showAds();

        this.previousViewport = this.currentViewport;
      }
    }, 500));
  }

  showAds() {

    this.adSlots = [];

    var elementsTotal = this.adElements.length;

    for (var index = 0; index < elementsTotal; index++) {

      var el = this.adElements[index];
      var adSlot = JSON.parse(el.getAttribute('data-params'));

      if (adSlot.devices.indexOf(this.currentViewport) > -1) {
        this.renderAdSlot(adSlot);
        // console.log(adSlot.name, adSlot.position);
      }

    }
  }

  renderAdSlot(adSlot) {

    googletag.cmd.push(() => {

      var mapping = null;
      var sizeMappingsTotal = adSlot.size_mapping.length;
      if (sizeMappingsTotal) {
        var m = googletag.sizeMapping();
        for (var index = 0; index < sizeMappingsTotal; index++) {
          var size_mapping_array = JSON.parse('[' + adSlot.size_mapping[index] + ']');
          m.addSize(size_mapping_array[0], size_mapping_array[1]);
        }
        m.addSize([0, 0], []).build();
        var mapping = m.build();
      }

      if (adSlot.fluid) {
        adSlot.sizes.push('fluid');
      }

      var slot = googletag.defineSlot('/' + adSlot.publisher + '/' + adSlot.slot, adSlot.sizes, adSlot.position);

      if (!slot) {
        return;
      }

      if (mapping) {
        slot.defineSizeMapping(mapping);
      }

      slot.addService(googletag.pubads());
      googletag.display(adSlot.position);

      this.adSlots.push(slot);

    });
  }

  destroyAd() {
    if (typeof googletag.pubads !== 'function') {
      return;
    }
    if (window.newsGalleryInlineAdSlot) {
      googletag.destroySlots([window.newsGalleryInlineAdSlot]);
    }
  }

  setViewport() {

    var windowWidth = this.getWindowWidth();

    if (this.firstLoad == false) {
      this.previousViewport = this.currentViewport;
    }

    for (var i = 0; i < this.params.breakpoints.length; i++) {
      if (windowWidth >= this.params.breakpoints[i]) {

        this.currentViewport = this.params.devices[i];
      }
    }

    if (this.firstLoad == true) {
      this.previousViewport = this.currentViewport;
    }

  }

  getWindowWidth() {
    return window.innerWidth || document.documentElement.clientWidth;
  }

}

export {
  Commercial as
    default
};
